.to-repo {
  display: -webkit-inline-flex;
  display: inline-flex;
  gap: 0.5em;
  background: #19a416;
  color: #fff;
  border-radius: 5px;
  padding: 0.5em 1em;
  text-decoration: none;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  cursor: pointer;
  font-weight: 900;
}
.to-repo.btn-dark {
  background: #080710;
}
.to-repo.btn-dark:hover {
  background: #0d0c17;
}
.to-repo.btn-dark:active {
  background: #1d1c23;
}
.to-repo:hover {
  background: #095a08;
}
.to-repo:active {
  background: #28272f;
  color: #fff;
  transition: none;
}
.to-repo:disabled {
  cursor: not-allowed;
}
.to-repo:disabled:hover {
  background: #19a416;
}
.to-repo svg {
  width: 1.125rem;
  height: 1.125rem;
}
.to-repo svg:hover {
  fill: #fff;
}

.to-reset {
  background: #3b4ce2;
}
.to-reset:hover {
  background: #2538df;
}
.btn-serious {
  background-color: #28272f;
}
.btn-serious:hover {
  color: #000;
}
.btn-dangerous {
  background-color: red;
  color: #d9d9d9;
}
.btn-dangerous:hover {
  background-color: rgb(134, 0, 0);
  color: #d9d9d9;
}
.btn-dangerous:active {
  background-color: rgb(62, 0, 0);
  color: #d9d9d9;
}
