:root {
  --main-color: #80b2e5;
  --main-text-color: #161616;
}
@font-face {
  font-family: "Font";
  font-weight: 200;
  font-style: normal;
  src: url("/public/font/ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: "Font";
  font-weight: 300;
  font-style: normal;
  src: url("/public/font/Light.woff2") format("woff2");
}
@font-face {
  font-family: "Font";
  font-weight: 400;
  font-style: normal;
  src: url("/public/font/Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Font";
  font-weight: 500;
  font-style: normal;
  src: url("/public/font/Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Font";
  font-weight: 600;
  font-style: normal;
  src: url("/public/font/DemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Font";
  font-weight: 700;
  font-style: normal;
  src: url("/public/font/Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Font";
  font-weight: 800;
  font-style: normal;
  src: url("/public/font/ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: "Font";
  font-weight: 900;
  font-style: normal;
  src: url("/public/font/Heavy.woff2") format("woff2");
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Font", sans-serif;
}

body {
  background-color: #28272f;
}
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px;
  margin: auto;
}

label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

input,
select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}
::-webkit-scrollbar {
  width: var(--scroll-size, 10px);
  height: var(--scroll-size, 10px);
}
::-webkit-scrollbar-track {
  background-color: var(--scroll-track, transparent);
  border-radius: var(--scroll-track-radius, var(--scroll-radius));
}
::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color, grey);
  background-image: var(--scroll-thumb, none);
  border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
}
:root {
  --scroll-size: 10px;
  --scroll-radius: 10px;
  --scroll-track: rgb(0 0 0 / 40%);
  --scroll-thumb-color: #dfdfdf;
}
* {
  scrollbar-color: var(--scroll-thumb-color, grey)
    var(--scroll-track, transparent);
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
