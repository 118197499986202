header {
  display: flex;
  align-items: center;
}
.slider-container {
  margin-bottom: 5px;
}

.slider-container input[type="range"] {
  width: 100%;
}
.switcher {
  height: 30px;
}
.switch {
  position: relative;
  bottom: 29px;
  left: 25px;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .icon::before {
  transform: rotate(45deg);
  padding-top: 5px;
}
input:checked + .icon::before {
  content: "";
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20.903" height="18.395" viewBox="0 0 22.903 19.395"><polygon points="22.903 2.828 20.075 0 6.641 13.435 3.102 9.09 0 11.616 6.338 19.395 22.903 2.828"/></svg>')
    no-repeat center center;
  background-size: 60%;
  width: 22px;
  height: 22px;
  background-color: #fff;
  padding-top: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 7px;
}
input + .icon::before {
  content: "";
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17.828" height="17.828" viewBox="0 0 17.828 17.828"><polygon points="2.828 17.828 8.914 11.742 15 17.828 17.828 15 11.742 8.914 17.828 2.828 15 0 8.914 6.086 2.828 0 0 2.828 6.085 8.914 0 15 2.828 17.828"/></svg>')
    no-repeat center center;
  background-size: 60%;
  width: 22px; /* Adjust as needed */
  height: 22px; /* Adjust as needed */
  padding-top: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.slider.w-color:before {
  background-color: #19a416;
}
.slider.w-color {
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider.w-color {
  background-color: #ccc;
}
input:checked + .slider.icon {
  background-color: #19a416;
}

.slider.icon:before {
  background-color: #d9d9d9;
}
.slider.icon:after {
  background-color: #d9d9d9;
}
input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.disabled-toggle {
  opacity: 0.5;
  cursor: default;
}
.optimizationButtons {
  display: flex;
  width: 100%;
  margin: 10px 0;
}
.optimizationButtons button {
  width: 95%;
  margin: 0 auto;
}
