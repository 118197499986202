#login {
  background-color: #080710;
  height: 100vh;
}
#login .background {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
/* .background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
} */
/* .shape:first-child {
  background: linear-gradient(#09c525, #003410);
  left: -80px;
  top: -80px;
}
.shape:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
} */
#login form {
  height: auto;
  width: 450px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 40px 35px;
}
#login form * {
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
#login form h1 {
  font-size: 2.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
  background-color: transparent;
  padding-bottom: 30px;
}

#login input {
  margin-top: -10px;
  display: block;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 300;
}
#login ::placeholder {
  color: #e5e5e5;
}
#login button {
  margin-top: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
}
