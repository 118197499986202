.notification {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 999999;
  transform: translateX(-50%);
  padding: 1em;
  border-radius: 10px;
  opacity: 0;
  font-weight: 800;
  animation: slideDown 3s forwards;
}

@keyframes slideDown {
  0% {
    top: -50px;
    opacity: 0;
  }
  10%,
  90% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: -50px;
    opacity: 0;
  }
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: #721c24 2px solid;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: #155724 2px solid;
}

.notification.info {
  background-color: #cce5ff;
  color: #004085;
  border: #004085 2px solid;
}
