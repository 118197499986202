.demo-page .demo-page-navigation.anime {
  width: 25em;
  border-radius: 10px;
}
.demo-page .demo-page-navigation.anime div .anime-review-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  padding-top: 30px;
}
.demo-page .demo-page-navigation.anime div div img {
  height: 360px;
  width: 244.8px;
  object-fit: cover;
  border-radius: 10px;
}

.demo-page .demo-page-navigation.anime h1 {
  font-weight: 900;
  font-size: 2.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  text-align: center;
}
.demo-page .demo-page-navigation.anime div h2 {
  font-size: 1.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-left: 10px;
}
.demo-page .demo-page-navigation.anime div p {
  font-size: 0.9rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  color: #d9d9d9;
  margin: 0 20px;
}
.demo-page .demo-page-navigation.anime div {
  background-color: #080710;
  border-radius: 10px;
}
.anime-content-review {
  display: block;
  padding: 10px;
  padding-bottom: 20px;
}
.anime-review-img {
  position: relative;
}

.anime-review-img h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
