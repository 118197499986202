nav {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.navigation ul li {
  padding-bottom: 25px;
}
.homeicons,
.hentaiicon {
  padding-left: 3px;
}
.videoicons {
  padding-left: 1px;
}
.sidbar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  overflow: hidden;
  background-color: #080710;
  height: 100vh;
}

.sidbar svg:hover path {
  fill: #797979;
  stroke: #797979;
  transition: all 0.3s ease-in-out;
}
.sidbar .videoicons svg:hover path {
  fill: none;
  stroke: #797979;
  transition: all 0.3s ease-in-out;
}
#logout {
  cursor: pointer;
}
.sidbar #logout svg:hover path {
  fill: red;
  stroke: red;
  transition: fill 1s ease-in-out;
  transition: stroke 1s ease-in-out;
}
.accountImg {
  pointer-events: none;
  user-select: none;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
/* background-color: #080710; */
/* background-color: rgba(255, 255, 255, 0.13); */
/* background-color: rgba(255, 255, 255, 0.07); */
#mainLayout {
  display: grid;
  grid-template-columns: 70px 1fr;
}
