.subtitle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.demo-page-navigation.video {
  width: 35em;
}
.demo-page-navigation.video p {
  margin: 5px 0 10px 0;
}
.demo-page-navigation.video nav {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.preview-title * {
  display: inline;
  vertical-align: baseline;
}

.preview-title span {
  background-color: #a028ff;
  font-size: 0.6rem;
  padding: 0.2rem 0.3rem;
  font-weight: 800;
  border-radius: 5px;
  margin-left: 5px;
  position: relative;
  top: -3px;
  white-space: nowrap;
}
.preview-title {
  padding: 10px;
}
.preview-description {
  background-color: #28272f;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
}
.preview-subtitles p {
  display: inline-block;
  background-color: burlywood;
  color: black;
  padding: 0.2rem 0.3rem;
  font-weight: 800;
  border-radius: 5px;
  cursor: default;
  margin-top: 10px !important;
  transition: background-color 0.5s ease-in-out;
}
.preview-subtitles p:hover {
  background-color: rgb(205, 153, 86);
}
.preview-subtitles p:not(:last-child) {
  margin-right: 10px !important;
}
.preview-subtitles {
  padding-left: 10px;
  margin-bottom: 10px;
}

.subtitle-container {
  display: flex;
  flex-wrap: wrap;
}

.inner-subtitle-container {
  width: 425px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-subtitle-container button {
  position: absolute !important;
  top: 0;
  right: 0;
}
.categories-container {
  margin: 20px 0;
}

.inner_categories_container {
  display: inline-block; /* or use flex */
  background-color: #28272f;
  margin: 0;
  padding: 0;
  margin-right: 10px;
  border-radius: 5px;
}

.inner_categories_container .nice-form-group {
  display: inline;
}

.inner_categories_container label {
  color: white;
  font-weight: 900 !important;
  font-size: 1.5rem;
  white-space: nowrap;
}
.inner_categories_container .nice-form-group {
  display: flex;
  justify-items: top;
  align-items: center;
  position: relative;
  top: -10px;
  padding: 0 10px;
}
.inner_categories_container .nice-form-group input {
  height: 23px;
  width: 20px;
}
.inner_categories_container:last-child {
  margin-right: 0;
}
.Categories p {
  display: inline-block;
  background-color: rgb(29, 124, 220);
  color: #fff;
  padding: 0.2rem 0.3rem;
  font-weight: 800;
  border-radius: 5px;
  cursor: default;
  margin-top: 10px !important;
  transition: all 0.5s ease-in-out;
}
.Categories p:not(:last-child) {
  margin-right: 10px !important;
}
.Categories p:hover {
  background-color: rgb(0, 76, 151);
}
.dropdown {
  position: relative;
  top: 10px;
  display: flex;
}

.dropdown-content {
  position: absolute;
  background-color: #28272f;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: #e07211 1px dashed;
  z-index: 99999;
  max-height: 300px;
  overflow: auto;
}

.dropdown-content div {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content div div {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.dropdown-content div div:hover {
  background-color: #080710;
  border-radius: 5px;
}
.mainlayout_content section.removeoverflow {
  overflow: visible !important;
}
.search-anime-layout {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.search-anime-layout > :last-child {
  flex-grow: 1; /* Grow to take up remaining space */
}
.search-anime-layout div img {
  width: 150px;
  aspect-ratio: 17/25;
}
.progresspopup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 150px;
  width: 450px;
  border-radius: 10px;
  z-index: 99999;
  background-color: #fff;
  display: grid;
  grid-template-columns: 70px 1fr;
}
.progressIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progressContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
}
.progressIcon svg {
  width: 30px;
  height: 30px;
}
.progressContent h1 {
  font-size: 0.9rem;
  font-weight: 900;
}
.progressContent p {
  font-size: 0.8rem;
}
.progressbarbutton {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
  gap: 20px;
}
.progressbarbutton button {
  padding: 0.2rem 0.8rem;
  font-size: 0.7rem;
  height: 25px;
  position: relative;
  bottom: 3px;
}
.progressbarbutton div p {
  text-align: right;
  font-size: 0.8rem;
  font-weight: 900;
  position: relative;
  top: 5px;
}
.progressbarbutton div progress {
  width: 100%;
}
.progressclosebutton {
  display: fixed;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.progressclosebutton svg {
  display: fixed;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.progressclosebutton svg:hover {
}
