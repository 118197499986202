@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.confirmation-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s;
  z-index: 99999;
}
.confirmation-popup div {
  display: flex;
  gap: 10px;
}
.confirmation-popup h2 {
  margin: 0 0 20px 0;
}

.confirmation-popup p {
  margin: 0 0 20px 0;
}

.confirmation-popup .confirm-button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.confirmation-popup .cancel-button {
  padding: 10px 20px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  visibility: hidden;
  z-index: 9999;
  visibility: visible;
}
