.status .website .card {
  background-color: #080710;
  border-radius: 10px;
  color: #9d9d9d;
  padding: 1rem;
}
.status .website .card .title {
  display: flex;
  align-items: center;
  color: #888792;
  font-size: 0.5rem;
  gap: 10px;
}
.status .website .card .data {
  font-size: 3rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status .website .card .title .svg {
  background-color: #4534b9;
  padding: 0.3rem;
  border-radius: 3px;
  color: #080710;
}

.status .website .card .title .svg {
  position: relative;
}

.status .website .card .title .svg svg {
  height: 18px;
}
.status .website .card .title .svg.video {
  position: relative;
  top: 2px;
}

.status .website .head_cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
