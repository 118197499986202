.demo-page .demo-page-navigation.category {
  width: 25em;
  border-radius: 10px;
}
.demo-page .demo-page-navigation.category div .category-review-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}
.demo-page .demo-page-navigation.category div div img {
  aspect-ratio: 16/9;
  height: 220px;
  object-fit: cover;
}
.demo-page .demo-page-navigation.category div .category-review-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the 0.5 to make the image darker or lighter */
}
.demo-page
  .demo-page-navigation.category
  div
  .category-review-img:hover::before {
  display: none;
}
.demo-page .demo-page-navigation.category div .category-review-img:hover h1 {
  display: none;
}
.demo-page .demo-page-navigation.category h1 {
  font-weight: 900;
  font-size: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  text-align: center;
}
.demo-page .demo-page-navigation.category div h2 {
  font-size: 1.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-left: 10px;
}
.demo-page .demo-page-navigation.category div p {
  font-size: 0.9rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  color: #d9d9d9;
  margin: 0 20px;
}
.demo-page .demo-page-navigation.category div {
  background-color: #080710;
  border-radius: 10px;
}
.category-content-review {
  display: block;
  padding: 10px;
  padding-bottom: 20px;
}
.category-review-img {
  position: relative;
}

.category-review-img h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
