.log .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  color: white;
  background-color: #080710;
}
.log .header p::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 0 9px 3px;
  background-color: green;
  border-radius: 50%;
}

.terminal {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  height: calc(100% - 90px);
  width: calc(100%- 20px);
  background-color: #080710;
  border-radius: 20px;
  color: white;
  margin: 20px;
  padding: 10px;
}
.log {
  height: 100vh;
}
