.status {
  display: grid;
  grid-template-columns: 4fr minmax(250px, 1fr);
  gap: 20px;
  margin: 0.7rem 2rem;
}
.status .server {
  background-color: #080710;
  border-radius: 10px;
}
.server {
  color: #dfdfdf;
  padding: 20px;
}
.server-stats div {
  margin-bottom: 20px;
}
.server-stats h3 {
  margin: 5px 0;
}
