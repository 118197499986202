header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
}
header h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #d9d9d9;
  user-select: none;
}
header .btn {
  font-size: 1rem;
  font-weight: 900;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: #d9d9d9;
  background: #080710;
  border: none;
  cursor: pointer;
}
header .btn:hover {
  background: #3a3a3a; /* darker background on hover */
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}
header .btn.btn_back {
  background-color: #333333;
  border-radius: 15px;
}
header .btn.btn_back:hover {
  background-color: #080710;
}
header div {
  display: flex;
  gap: 1rem;
}
